import { Link } from "gatsby"
import React from "react"

import * as Styles from "./Footer.module.scss"


const Footer = () => {
	return (
		<footer className={ Styles.footer }>
			<ul>
				<li>
					<Link to={ "/" }>
						Start
					</Link>
				</li>
			</ul>
			<ul className={ Styles.right }>
				<li>
					<Link to={ "/dataprivacy" }>
						Datenschutzhinweise
					</Link>
				</li>

				<li>
					<Link to={ "/imprint" }>
						Impressum
					</Link>
				</li>
			</ul>
		</footer>
	)
}

export default Footer;